import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, lazy, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Home_page = lazy(() => import('./pages/home'));
const Product_page = lazy(() => import('./pages/product'));
const NotFound = lazy(() => import('./pages/not_found'));

const LoadComponent = (props) => {
  const Component = props.element;
  return (
    <Suspense
      fallback={
        <div className="layout-centered">
          <img alt="logo" src="/media/thedush-logo.png" />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

function App(props) {
  const [productIndex, setProductIndex] = useState(1);
  return (
    <React.StrictMode>
      <BrowserRouter>
        <div className="app">
          <React.Fragment>
            <Routes>
              <Route
                path="/"
                exact={true}
                element={
                  <LoadComponent
                    element={Home_page}
                    setProductIndex={setProductIndex}
                  />
                }
              />
              <Route
                path="/product"
                exact={true}
                element={
                  <LoadComponent
                    element={Product_page}
                    productIndex={productIndex}
                    setProductIndex={setProductIndex}
                  />
                }
              />
              <Route path="*" element={<LoadComponent element={NotFound} />} />
            </Routes>
          </React.Fragment>
        </div>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
